export interface IUser {
    name: string;
    isBotActivated: boolean;
}

export interface IMeeting {
    meeting_id: string;
    event_name: string;
    timestamp: number;
    recording_enabled: boolean;
}

export interface IPreference {
    digest_enabled: boolean;
    digest_time: string;
    digest_timezone: string;
    archive_mailbot_mails_enabled: boolean;
    archive_mailbot_mails_after: string;
    ai_auto_responder_enabled: boolean;
    first_time_sender_treatment: string;
    jarvis_enabled: boolean;
}

export interface ISenderProfile {
    id: number;
    sender_email: string;
    sender_name: string;
    sender_domain: string;
    total_count: number;
    scanned_count: number;
    read_fraction: number;
    user_training: string | null;
    user_action: string | null;
    user_action_reason: string | null;
    blocked_by_emailzap: boolean;
    can_unsubscribe: boolean;
    unsubscribed: boolean;
}

export interface IOption {
    label: string;
    value: string;
}

export interface ISubscription {
    id: string;
    customer: string;
    price: IPricing;
    product: string;
    cancel_at_period_end: boolean;
    current_period_start: string;
    current_period_end: string;
    cancel_at: string;
    status: string;
    metadata: object;
    extra_metadata: any;
    expired: boolean;
    phases: any[];
}

export interface IApplication {
    id: number;
    name: string;
    tag: string;
    description: string;
    trial_period: number;
}

export interface IPricing {
    id: string;
    product: string;
    nickname: string;
    currency: string;
    interval: string;
    unit_amount: number;
    interval_count: number;
    metadata: object;
    amount_off: number;
    percentage_off: number;
    duration_in_months: number;
}

export interface IInvoice {
    id: number;
    customer: string;
    hosted_invoice_url: string;
    status: string;
    amount_paid: number;
    created: string;
}

export type OrderDirection = "asc" | "desc";

export enum WorkflowState {
    Initiated = "initiated",
    Processing = "processing",
    Configured = "configured",
    Failed = "failed",
}

export interface IWorkflow {
    id: number;
    assistant?: string;
    user_query?: string;
    state: string;
    json_workflow?: IJsonWorkflow | null;
    is_active?: boolean;
    created?: string;
    name: string;
}

export interface IJsonWorkflow {
    actions: IJsonWorkflowAction[];
    constraints: IJsonWorkflowConstraint[][];
    trigger: IJsonWorkflowTrigger;
}

export interface IJsonWorkflowTrigger {
    tag: string;
    params: object;
}

export interface IJsonWorkflowAction {
    tag: string;
    params: object;
}

export interface IJsonWorkflowConstraint {
    tag: string;
    params: object;
    description: string;
}

export interface IJsonWorkflowSection {
    name: string;
    key: "trigger" | "actions" | "constraints";
}
